<template>
  <div class="wrapper">
    <h4>社区管理</h4>
    <el-row style="height:100%;">
      <el-col :span="18" :offset="3" style="height:90%;">
        <el-table
          ref="table"
          height="100%"
          :data="tableData"
          style="width: 100%"
          row-key="id"
          border
          lazy
          :load="loadNode"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column prop="id" label="id" />
          <el-table-column prop="regionName" label="名称" />
          <el-table-column prop="regionPath" label="路径" />
          <el-table-column prop="sort" label="排序" width="100" />
          <el-table-column fixed="right" label="操作" width="250">
            <template slot-scope="scope">
              <el-button v-permission="'CommunityEditButton'" type="text" size="small" @click="edit(scope.row)">编辑</el-button>
              <el-button v-permission="'CommunityAddButton'" type="text" size="small" @click="append(scope.row, scope.$index)">添加子节点</el-button>
              <el-button v-permission="'CommunityDeleteButton'" type="text" size="small" @click="remove(scope.row, scope.$index)">删除节点</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

  </div>
</template>
<script>
import { mapActions } from 'vuex'
const id = 1000
export default {
  name: 'Manage',
  data() {
    return {
      tableData: []
    }
  },
  created() {
    this.getRegionNode(101)
  },
  methods: {
    ...mapActions([
      'getRegionServe',
      'addRegionServe',
      'editRegionServe',
      'delRegionServe'
    ]),
    getRegionNode(val, treeNode, resolve) {
      const params = {
        pid: val
      }
      const retData = []
      this.getRegionServe(params).then((res) => {
        if (res.code == 200) {
          let retData = res.data
          if (retData.length == 0) {
            resolve(retData)
            return
          }
          retData = retData.map(item => {
            item.hasChildren = true
            item.children = []
            return item
          })
          if (val === 101) {
            this.tableData = retData
          } else {
            resolve(retData)
            treeNode.children = retData
          }
        }
      })
      // return retData
    },
    async loadNode(tree, treeNode, resolve) {
      await this.getRegionNode(tree.id, tree, resolve)
    },
    append(data, index) {
      this.$prompt('请输入节点名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputErrorMessage: '输入不能为空'
      }).then(({ value }) => {
        // 后台添加
        const param = {
          pid: data.id,
          regionName: value,
          type: parseInt(data.type) + 1,
          sort: 100
        }
        this.addRegionServe(param).then((res) => {
          if (res.code == 200) {
            const retData = res.data
            retData.hasChildren = true
            retData.children = []
            data.children.push(retData)

            this.$nextTick(() => {
              this.$refs.table.toggleRowExpansion(data, true)
              this.$refs.table.doLayout()
            })

            // this.$set(data, "expanded", true);
          }
        })
      }).catch(() => {

      })
    },
    edit(data) {
      this.$prompt('请输入节点名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputValue: data.regionName,
        inputErrorMessage: '输入不能为空'
      }).then(({ value }) => {
        //
        const param = {
          id: data.id,
          regionName: value
        }
        this.editRegionServe(param).then((res) => {
          if (res.code == 200) {
            const retData = res.data
            retData.hasChildren = true
            data.regionName = value
            data.expanded = true
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          }
        })
      }).catch(() => {

      })
    },

    remove(data, index) {
      if (data.children.length > 0) {
        this.$message.error('存在子节点不能删除，请先删除子节点')
      }
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const parent = this.findParentNode(data, this.tableData)
        this.delRegionServe(data.id).then((res) => {
          if (res.code == 200) {
            const retData = res.data
            parent.children.splice(parent.children.indexOf(data), 1)
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          }
        })
      })
    },
    findParentNode(node, list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].children && list[i].children.includes(node)) {
          return list[i] // 当前节点是传入节点的父节点
        } else if (list[i].children && Array.isArray(list[i].children)) {
          // 递归查找当前节点的子节点
          const parent = this.findParentNode(node, list[i].children)
          if (parent) {
            return parent
          }
        }
      }
      return null
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
    h4 {
        height: 40px;
        line-height: 40px;
        color: #f26333;
        padding-left: 20px;
    }
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}
</style>
